import "./TripList.css";

import {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import MilleretteAppBar from "../navigation/MilleretteAppBar";
import TripAddEdit from "../inputs/TripForm";

import List from "./List";

// hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// mui components
import {Box, Button, Container, Dialog, Grid} from "@mui/material";


const TripList = () => {

  const [ trips, setTrips ] = useState([]);
  const [ createDialogOpen, setCreateDialogOpen ] = useState(false);
  const [ updateDialogOpen, setUpdateDialogOpen ] = useState(false);

  const [ tripDefaultValue, setTripDefaultValue ] = useState({});

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getTrips = async () => {
      await axiosPrivate.get( "/trips/?season=current", {
        signal: controller.signal
      })
        .then(
          function (response) {
            isMounted && setTrips(response.data);
          })
        .catch(
          function (err) {
            console.error(err);
            navigate("/login", { state: { from: location }, replace: true });
          }
        );
    };

    getTrips();

    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  }

  const handleOpenUpdateDialog = (defaultValue) => {
    setTripDefaultValue(defaultValue)
    setUpdateDialogOpen(true);
  }

  const handleDeleteTrip = async (trip) => {
    const response = await axiosPrivate.delete(trip.url)
      .then(
        (response) => {
          console.log("Successful DELETE request to the back-end");
          const updatedTrips = trips.filter((item) => item.url !== trip.url);
          setTrips(updatedTrips);
        }
      )
      .catch(
        err => {
          console.log("Delete request failed with error", err);
        }
      );

    console.log(response);
  }

  const handleAddInvitationToTrip = async (tripToUpdate, guest) => {
    await axiosPrivate.post(
      "/invitations/",
      {
        "trip_url": tripToUpdate.url,
        "guest_url": guest.url
      }
    )
      .then(
        (response) => {
          const newTripList = trips.map((trip) => {
            if (trip.url === tripToUpdate.url) {
              const updatedInvitations = trip.invitations.concat(response.data);
              return {
                ...trip,
                invitations: updatedInvitations,
              };
            }
            return trip;
          });
          setTrips(newTripList);
        }
      )
      .catch(
        (err) => {
          console.error(err);
        }
      )
  }

  const handleRemoveInvitationFromTrip = async (tripToUpdate, invitation) => {
    console.log("deleting invitation ", invitation);
    await axiosPrivate.delete(invitation.url)
      .then(
        (response) => {
          console.log("Successful response for deleting an invitation: ", response);
          const newTripList = trips.map((trip) => {
            if (trip.url === tripToUpdate.url) {
              const updatedInvitations = trip.invitations.filter((item) => item.url !== invitation.url);
              return {
                ...trip,
                invitations: updatedInvitations,
              };
            }
            return trip;
          });
          setTrips(newTripList);
        }
      )
      .catch(
        (err) => {
          console.error(err);
        }
      )
  }

  return (
    <>
      <MilleretteAppBar />

      <Container>

        <Dialog
          open={createDialogOpen}
          onClose={handleOpenCreateDialog}
        >
          <TripAddEdit
            title="Publiez votre séjour à la Millerette"
            helperText="Entrez les détails du nouveau séjour"
            isAddMode={true}
            setDialogOpen={setCreateDialogOpen}
            submitButtonText="Créer"
            trips={trips}
            setTrips={setTrips}
          />
        </Dialog>

        <Dialog
          open={updateDialogOpen}
          onClose={handleOpenUpdateDialog}
        >
          <TripAddEdit
            title="Mettez à jour votre séjour à la Millerette"
            helperText="Modifiez les détails de votre séjour"
            isAddMode={false}
            setDialogOpen={setUpdateDialogOpen}
            submitButtonText="Mettre à jour"
            trips={trips}
            setTrips={setTrips}
            defaultValue={tripDefaultValue}
          />
        </Dialog>

        <div className="trip-list">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={() => handleOpenCreateDialog({ text: '' })}
                sx={{position: "fixed"}}
              >
                Nouveau voyage
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Box component="div" sx={{ overflow: 'auto' }}>
                <h1>Voyages à la Millerette</h1>
                {trips?.length
                  ? (
                    <List
                      className="trip-list"
                      list={trips}
                      handleDelete={handleDeleteTrip}
                      handleOpenForm={handleOpenUpdateDialog}
                      handleAddInvitationToTrip={handleAddInvitationToTrip}
                      handleRemoveInvitationFromTrip={handleRemoveInvitationFromTrip}
                    />
                  ) : <p>Aucun voyage prévu à La Millerette</p>
                }
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default TripList;
