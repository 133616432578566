import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './interceptors/axios';

import { AuthProvider } from './context/AuthProvider';

import App from './App';
import './index.css';

const theme = createTheme({
  palette: {
      type: 'light',
      primary: {
        main: '#5d7aa3',
      },
      secondary: {
        main: '#403a45',
      },
      error: {
        main: '#c61432',
      },
      text: {
        primary: '#403a45',
        secondary: '#403a45',
      },
      info: {
        main: '#bfb6a8',
      },
      divider: '#bfb6a8',
      action: {
        disabled: '#bfb6a8',
        background: '#c61432'
      }
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </>
);

