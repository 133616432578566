import Item from "./Item";
import useAuth from "../../hooks/useAuth";

const List = ({ list, handleDelete, handleOpenForm, handleAddInvitationToTrip, handleRemoveInvitationFromTrip }) => {

  const { auth } = useAuth();

  return (
    <div className="list">
      <ul>
        {list.map(item => {
        return (
          <li key={item.url}>
            {
              <Item
                loggedUserName={auth?.displayName}
                trip={item}
                handleDelete={() => handleDelete(item)}
                handleOpenForm={() => handleOpenForm(item)}
                handleAddInvitationToTrip={(guest) => handleAddInvitationToTrip(item, guest)}
                handleRemoveInvitationFromTrip={(invitation) => handleRemoveInvitationFromTrip(item, invitation)}
              />
            }
          </li>
        )
      })}
      </ul>
    </div>
  );
}

export default List;