import TripGuests from "./TripGuests";


const Invitations = ({ isLoggedUser, invitations, handleAddInvitationToTrip, handleRemoveInvitationFromTrip }) => {

  return (
    <TripGuests
      isLoggedUser={isLoggedUser}
      invitationList={invitations}
      handleAddInvitationToTrip={handleAddInvitationToTrip}
      handleRemoveInvitationFromTrip={handleRemoveInvitationFromTrip}
    />
  )
}

export default Invitations;