import axios from '../interceptors/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.post(
      "/auth/token/refresh",
      { refresh: sessionStorage.getItem("refreshToken") }
    )
    setAuth(prev => {
      console.log(JSON.stringify(prev));
      console.log(response.data);
      return {...prev, accessToken: response.data.access}
    });
    return response.data.access;
  }

  return refresh;
}

export default useRefreshToken;