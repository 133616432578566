import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {green} from "@mui/material/colors";
import {useState} from "react";

// hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const GuestAddEdit = (
  {title, helperText, submitButtonText, isAddMode, setDialogOpen, guests, setGuests, defaultValue }
) => {

  const [ firstName, setFirstName ] = useState(defaultValue === undefined ? '' : defaultValue.first_name);
  const [ lastName, setLastName ] = useState(defaultValue === undefined ? '' : defaultValue.last_name);

  const [ firstNameError, setFirstNameError ] = useState(false);
  const [ firstNameHelperText, setFirstNameHelperText ] = useState('');
  const [ lastNameError, setLastNameError ] = useState(false);
  const [ lastNameHelperText, setLastNameHelperText ] = useState('');

  const [ waitingForGuestUpdate, setWaitingForGuestUpdate ] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const onSubmit = () => {
    return isAddMode
      ? createGuest()
      : updateGuest()
  }

  const createGuest = async () => {
    setWaitingForGuestUpdate(true);
    await axiosPrivate.post(
      "/guests/",
      {
        "first_name": firstName,
        "last_name": lastName
      }
    )
      .then(
        (response) => {
          setWaitingForGuestUpdate(false);
          const updatedGuests = guests.concat(response.data);
          setGuests(updatedGuests);
          setDialogOpen(false);
        }
      )
      .catch(
        err => {
          setWaitingForGuestUpdate(false);
          if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Object.keys(err.response.data).map((key, index) => {
              if (key === "first_name") {
                setFirstNameError(true);
                setFirstNameHelperText(err.response.data[key][0]);
              }
              if (key === "last_name") {
                setLastNameError(true);
                setLastNameHelperText(err.response.data[key][0]);
              }
            })
          } else if (err.request) {
            // The request was made but no response was received
            // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
          }
        }
      );
  }

  const updateGuest = async () => {
    setWaitingForGuestUpdate(true);
    await axiosPrivate.patch(
      defaultValue.url,
      {
        "first_name": firstName,
        "last_name": lastName,
      }
    )
      .then(
        (response) => {
          setWaitingForGuestUpdate(false);
          const updatedGuests = guests.map((guest) => {
            if (guest.url === defaultValue.url) {
              return response.data;
            }
            return guest;
          });
          setGuests(updatedGuests);
          setDialogOpen(false);
        }
      )
      .catch(
        err => {
          setWaitingForGuestUpdate(false);
          // err.response.data.forEach((e) => console.log(e));
          if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Object.keys(err.response.data).map((key, index) => {
              if (key === "first_name") {
                setFirstNameError(true);
                setFirstNameHelperText(err.response.data[key][0])
              }
              if (key === "last_name") {
                setLastNameError(true);
                setLastNameHelperText(err.response.data[key][0]);
              }
            })
          } else if (err.request) {
            // The request was made but no response was received
            // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
          }
        }
      );

  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{helperText}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Prénom"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            setFirstName(e.target.value);
            setFirstNameError(false);
            setFirstNameHelperText('');
          }}
          defaultValue={firstName}
          error={firstNameError}
          helperText={firstNameHelperText}
        />
        <TextField
          margin="dense"
          id="title"
          label="Nom de famille"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            setLastName(e.target.value);
            setLastNameError(false);
            setLastNameHelperText('');
          }}
          defaultValue={lastName}
          error={lastNameError}
          helperText={lastNameHelperText}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Annuler</Button>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            variant="contained"
            disabled={waitingForGuestUpdate}
            onClick={onSubmit}
          >
            {submitButtonText}
          </Button>
          {waitingForGuestUpdate && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </>
  );
}

export default GuestAddEdit;
