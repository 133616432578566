import * as React from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTime} from "luxon";

const BasicDatePicker = ({ label, value, setValue, error, helperText }) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={(newValue) => setValue(DateTime.fromHTTP(newValue).toFormat('yyyy-MM-dd'))}
        renderInput={
          (params) => <TextField
            {...params}
            error={error}
            helperText={helperText}
          />}
        inputFormat="DD / MM / YYYY"
      />
    </LocalizationProvider>
  );
}

export default BasicDatePicker;