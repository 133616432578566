import './App.css';

import { Route, Routes } from 'react-router-dom';

import Guests from "./Components/Guests/Guests";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import TripList from "./Components/TripList/TripList";
import Layout from './Components/Layout';
import RequireAuth from './Components/Auth/RequireAuth';
import Users from "./Components/Auth/Users";

import PageNotFound from "./Components/pages/PageNotFound";
import Unauthorized from "./Components/pages/Unauthorized";

import {ROLES} from "./config/config";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* protected routes */}
        <Route element={<RequireAuth allowedRoles={[ROLES.user]}/>}>
          <Route path="/" element={<TripList />} />
          <Route path="guests" element={<Guests />} />

          {/* admin only routes */}
          <Route element={<RequireAuth allowedRoles={[ROLES.admin]}/>}>
            <Route path="users" element={<Users />} />
          </Route>

        </Route>


        {/* catch all */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
