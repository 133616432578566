import "./GuestList.css";

import React, {useEffect, useState} from 'react';

import {Box, Button, Container, Dialog, Grid} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import MilleretteAppBar from "../navigation/MilleretteAppBar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useLocation, useNavigate} from "react-router-dom";
import GuestAddEdit from "../inputs/GuestForm";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Guests = () => {

  const [ guests, setGuests ] = useState([]);
  const [ createDialogOpen, setCreateDialogOpen ] = useState(false);
  const [ updateDialogOpen, setUpdateDialogOpen ] = useState(false);

  const [ guestDefaultValue, setGuestDefaultValue ] = useState({});

  // search
  const [search, setSearch] = useState('')

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getGuests = async () => {
      await axiosPrivate.get( "/guests/", {
        signal: controller.signal
      })
        .then(
          function (response) {
            isMounted && setGuests(response.data);
          })
        .catch(
          function (err) {
            console.error(err);
            navigate("/login", { state: { from: location }, replace: true });
          }
        );
    };

    getGuests();

    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  }

  const handleOpenUpdateDialog = (defaultValue) => {
    setGuestDefaultValue(defaultValue)
    setUpdateDialogOpen(true);
  }

  const handleDeleteGuest = async (guest) => {
    const response = await axiosPrivate.delete(guest.url)
      .then(
        (response) => {
          console.log("Successful DELETE request to the back-end");
          const updatedTrips = guests.filter((item) => item.slug !== guest.slug);
          setGuests(updatedTrips);
        }
      )
      .catch(
        err => {
          console.log("Delete request failed with error", err);
        }
      );

    console.log(response);
  }

  return(
    <>

      <MilleretteAppBar />

      <Container>

        <Dialog
          open={createDialogOpen}
          onClose={handleOpenCreateDialog}
        >
          <GuestAddEdit
            title="Enregistrez un nouvel invité"
            helperText="Indiquez les nom et prénom de la personne"
            isAddMode={true}
            setDialogOpen={setCreateDialogOpen}
            submitButtonText="Ajouter"
            guests={guests}
            setGuests={setGuests}
          />
        </Dialog>

        <Dialog
          open={updateDialogOpen}
          onClose={handleOpenUpdateDialog}
        >
          <GuestAddEdit
            title="Modifiez le profil d'un invité"
            helperText="Entrez les nom et prénom de la personne"
            isAddMode={false}
            setDialogOpen={setUpdateDialogOpen}
            submitButtonText="Corriger"
            guests={guests}
            setGuests={setGuests}
            defaultValue={guestDefaultValue}
          />
        </Dialog>

        <div className="guest-list">

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={() => handleOpenCreateDialog({ text: '' })}
                sx={{position: "fixed"}}
              >
                Nouvel invité
              </Button>
            </Grid>
            <Grid item xs={10}>

              <Box component="div" sx={{ overflow: 'auto' }}>
                <h1>Liste des invités</h1>
                {guests?.length
                  ? (
                    <div style={{ height: '100%', width: '100%' }}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell><h3>Prénom</h3></TableCell>
                              <TableCell><h3>Nom</h3></TableCell>
                              <TableCell align="right" />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {guests.map((guest) => (
                              <TableRow
                                key={guest.slug}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {guest.first_name}
                                </TableCell>
                                <TableCell>{guest.last_name}</TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => handleDeleteGuest(guest)}
                                  >
                                    <DeleteIcon color='primary' />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => handleOpenUpdateDialog(guest)}
                                  >
                                    <EditIcon  color='primary' />
                                  </IconButton></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ) : <p>Aucun invité n'est encore enregistré</p>
                }
              </Box>

            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default Guests;