import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material";
import BasicDatePicker from "./BasicDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import {green} from "@mui/material/colors";
import { useState } from "react";

// hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const TripAddEdit = (
  { title, helperText, submitButtonText, isAddMode, setDialogOpen, trips, setTrips, defaultValue }
) => {

  const [ description, setDescription ] = useState(defaultValue === undefined ? '' : defaultValue.text);
  const [ startDate, setStartDate ] = useState(defaultValue === undefined ? null : defaultValue.start_date);
  const [ endDate, setEndDate ] = useState(defaultValue === undefined ? null : defaultValue.end_date);

  const [ descriptionError, setDescriptionError ] = useState(false);
  const [ descriptionHelperText, setDescriptionHelperText ] = useState('');
  const [ startDateError, setStartDateError ] = useState(false);
  const [ startDateHelperText, setStartDateHelperText ] = useState('');
  const [ endDateError, setEndDateError ] = useState(false);
  const [ endDateHelperText, setEndDateHelperText ] = useState('');

  const [ waitingForTripUpdate, setWaitingForTripUpdate ] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const onSubmit = () => {
    return isAddMode
      ? createTrip()
      : updateTrip()
  }

  const createTrip = async () => {
    setWaitingForTripUpdate(true);
    await axiosPrivate.post(
      "/trips/",
      {
        "text": description,
        "start_date": startDate,
        "end_date": endDate
      }
    )
      .then(
        (response) => {
          setWaitingForTripUpdate(false);
          const updatedTrips = trips.concat(response.data);
          setTrips(updatedTrips);
          setDialogOpen(false);
        }
      )
      .catch(
        err => {
          setWaitingForTripUpdate(false);
          if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Object.keys(err.response.data).map((key, index) => {
              if (key === "start_date") {
                setStartDateError(true);
                setStartDateHelperText(err.response.data[key][0]);
              }
              if (key === "end_date") {
                setEndDateError(true);
                setEndDateHelperText(err.response.data[key][0]);
              }
              if (key === "text") {
                setDescriptionError(true);
                setDescriptionHelperText(err.response.data[key][0])
              }
            })
          } else if (err.request) {
            // The request was made but no response was received
            // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
          }
        }
      );
  }

  const updateTrip = async () => {
    setWaitingForTripUpdate(true);
    await axiosPrivate.patch(
      defaultValue.url,
      {
        "text": description,
        "start_date": startDate,
        "end_date": endDate
      }
    )
      .then(
        (response) => {
          setWaitingForTripUpdate(false);
          const updatedTrips = trips.map((trip) => {
            if (trip.url === defaultValue.url) {
              return response.data;
            }
            return trip;
          });
          setTrips(updatedTrips);
          setDialogOpen(false);
        }
      )
      .catch(
        err => {
          setWaitingForTripUpdate(false);
          // err.response.data.forEach((e) => console.log(e));
          if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Object.keys(err.response.data).map((key, index) => {
              if (key === "start_date") {
                setStartDateError(true);
                setStartDateHelperText(err.response.data[key][0]);
              }
              if (key === "end_date") {
                setEndDateError(true);
                setEndDateHelperText(err.response.data[key][0]);
              }
              if (key === "text") {
                setDescriptionError(true);
                setDescriptionHelperText(err.response.data[key][0])
              }
            })
          } else if (err.request) {
            // The request was made but no response was received
            // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
          }
        }
      );

  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{helperText}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => setDescription(e.target.value)}
          defaultValue={description}
          error={descriptionError}
          helperText={descriptionHelperText}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BasicDatePicker
              label="jour d'arrivée"
              value={startDate}
              setValue={setStartDate}
              error={startDateError}
              helperText={startDateHelperText}
            />
          </Grid>
          <Grid item xs={6}>
            <BasicDatePicker
              label="jour de départ"
              value={endDate}
              setValue={setEndDate}
              error={endDateError}
              helperText={endDateHelperText}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Annuler</Button>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            variant="contained"
            disabled={waitingForTripUpdate}
            onClick={onSubmit}
          >
            {submitButtonText}
          </Button>
          {waitingForTripUpdate && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </>
  );
}

export default TripAddEdit;
