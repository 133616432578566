import {useState} from "react";

import Card from '@mui/material/Card';
import {CardHeader} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {CardContent} from "@mui/material";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import {CardActions} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Collapse from '@mui/material/Collapse';

import { styled, useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DateTime } from "luxon";
import Invitations from "../Guests/Invitations";

const DATE_DISPLAY_FORMAT = 'ccc dd LLLL kkkk';
const DATE_INPUT_FORMAT = 'yyyy-MM-dd';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Item = ({ loggedUserName, trip, handleDelete, handleOpenForm, handleAddInvitationToTrip, handleRemoveInvitationFromTrip }) => {

  const [expanded, setExpanded] = useState(false);

  const isLoggedUser = loggedUserName === trip.user;
  const theme = useTheme();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card elevation={6} className="item">
      <CardHeader
        className="item-header-title"
        disableTypography={true}
        avatar={
          <Avatar sx={{ bgcolor: theme.palette.text.secondary }} aria-label="recipe">
            {trip.user.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={<Typography variant="h6">{trip.text}</Typography>}
      />
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <CardContent>
            <Typography variant="body2" color="text.primary">
              du <b>{DateTime.fromFormat(trip.start_date, DATE_INPUT_FORMAT).setLocale("fr").toFormat(DATE_DISPLAY_FORMAT)}</b>
            </Typography>
            <Typography variant="body2" color="text.primary">
              au <b>{DateTime.fromFormat(trip.end_date, DATE_INPUT_FORMAT).setLocale("fr").toFormat(DATE_DISPLAY_FORMAT)}</b>
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
      <CardActions disableSpacing>
        {
          isLoggedUser &&
          <CardActions disableSpacing>
            <IconButton
              aria-label="delete"
              onClick={handleDelete}
            >
              <DeleteIcon color='primary' />
            </IconButton>
            <IconButton
              onClick={handleOpenForm}
            >
              <EditIcon  color='primary' />
            </IconButton>
          </CardActions>
        }
        {trip.invitations.length} {trip.invitations.length <= 1 ? "invité" : "invités"}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6">Invités</Typography>
          <Invitations
            isLoggedUser={isLoggedUser}
            invitations={trip.invitations}
            handleAddInvitationToTrip={handleAddInvitationToTrip}
            handleRemoveInvitationFromTrip={handleRemoveInvitationFromTrip}
          />
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default Item;