import * as React from 'react';
import { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import axios from '../../interceptors/axios';

const REGISTER_URL = "/users/"


export default function Register() {

  const navigate = useNavigate();

  const [ firstNameError, setFirstNameError ] = useState(false);
  const [ firstNameErrMsg, setFirstNameErrMsg ] = useState();

  const [ lastNameError, setLastNameError ] = useState(false);
  const [ lastNameErrMsg, setLastNameErrMsg ] = useState();

  const [ pseudoError, setPseudoError ] = useState(false);
  const [ pseudoErrMsg, setPseudoErrMsg ] = useState();

  const [ emailError, setEmailError ] = useState(false);
  const [ emailErrMsg, setEmailErrMsg ] = useState();

  const [ passwordError, setPasswordError ] = useState(false);
  const [ passwordErrMsg, setPasswordErrMsg ] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setFirstNameErrMsg(null);
    setLastNameErrMsg(null);
    setPseudoErrMsg(null);
    setEmailErrMsg(null);
    setPasswordErrMsg(null);

    const data = new FormData(event.currentTarget);
    const back_formatted_data = {
      first_name: data.get('firstName'),
      last_name: data.get('lastName'),
      display_name: data.get('pseudo'),
      email: data.get('email'),
      password: data.get('password'),
    };

    await axios.post(
      REGISTER_URL,
      back_formatted_data
    )
      .then(
        (response) => {
          console.log("Successful registration as", response?.data?.display_name);
          navigate("/login");
        },
        (err) => {
          if (!err?.response) {
            console.error('No server response');
          } else if (err.response?.status === 400) {
            if (!!err?.response?.data?.first_name) {
              setFirstNameError(true);
              setFirstNameErrMsg(err.response.data.first_name);
            }
            if (!!err?.response?.data?.last_name) {
              setLastNameError(true);
              setLastNameErrMsg(err.response.data.last_name);
            }
            if (!!err?.response?.data?.display_name) {
              setPseudoError(true);
              setPseudoErrMsg(err.response.data.display_name);
            }
            if (!!err?.response?.data?.email) {
              setEmailError(true);
              setEmailErrMsg(err.response.data.email);
            }
            if (!!err?.response?.data?.password) {
              setPasswordError(true);
              setPasswordErrMsg(err.response.data.password);
            }
          } else {
            console.error('Registration failed', err);
          }
        }
      )
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Inscription
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="Prénom"
                autoFocus
                error={firstNameError}
                helperText={firstNameErrMsg}
                onChange={() => {setFirstNameError(false)}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                autoComplete="family-name"
                error={lastNameError}
                helperText={lastNameErrMsg}
                onChange={() => {setLastNameError(false)}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="pseudo"
                label="Surnom"
                name="pseudo"
                autoComplete="pseudo"
                error={pseudoError}
                helperText={pseudoErrMsg}
                onChange={() => {setPseudoError(false)}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Addresse e-mail"
                name="email"
                autoComplete="email"
                error={emailError}
                helperText={emailErrMsg}
                onChange={() => {setEmailError(false)}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="new-password"
                error={passwordError}
                helperText={passwordErrMsg}
                onChange={() => {setPasswordError(false)}}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            S'inscrire
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login">
                Déjà inscrit ? Se connecter
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}