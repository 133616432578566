import axios from "axios";
import REACT_APP_API_URL from "../config/config";

export default axios.create({
  baseURL: REACT_APP_API_URL
});

export const axiosPrivate = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});