import Button from '@mui/material/Button';

export default function PageNotFound() {
  return (
    <div className="not-found">
      <h3>Page inconnue !</h3>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Accueil
      </Button>
    </div>
  )
}