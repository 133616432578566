
const Users = () => {

  return (
    <>
      <h1>Only accessible to admin users !</h1>
    </>
  )
}

export default Users;