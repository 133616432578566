import { useState, useEffect } from "react";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import {Autocomplete, TextField} from "@mui/material";

const TripGuests = ({ isLoggedUser, invitationList, handleAddInvitationToTrip, handleRemoveInvitationFromTrip }) => {

  const [ guestOptions, setGuestOptions ] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  const onSearch = async (s) => {
    await axiosPrivate.get("/guests/", {
      params: {search: s}
    })
      .then(
        function (response) {
          setGuestOptions(response.data);
        })
      .catch(
        function (err) {
          console.error(err);
        }
      );
  }

  const onChange = (event, updatedGuestList) => {
    console.log("Inside onChange:")
    console.log(event, updatedGuestList);
    const guestList = invitationList.map((invitation) => {return invitation.guest});

    // looking for additions to the guest list
    let addedGuests = updatedGuestList.filter(x => !guestList.includes(x));
    addedGuests.forEach(guest => {
      console.log(guest, " was ADDED to the guest list");
      handleAddInvitationToTrip(guest);
    });

    // looking for removals from the guest list
    let removedGuests = guestList.filter(x => !updatedGuestList.includes(x));
    removedGuests.forEach(guest => {
      let invitation = invitationList.find(x => x.guest.url === guest.url);
      handleRemoveInvitationFromTrip(invitation);
    });

  };

  return <Autocomplete
    multiple
    readOnly={!isLoggedUser}
    renderInput={(params) => <TextField
      {...params}
      label="Invités"
      onChange={e => onSearch(e.target.value)}
    />}
    options={guestOptions}
    getOptionLabel={(guest) => guest === undefined ? '' : guest.first_name + ' ' + guest.last_name}
    isOptionEqualToValue={(option, value) => option.url === value.url}
    value={invitationList.map((invitation) => invitation.guest)}
    onChange={onChange}
    blurOnSelect={true}
    clearOnBlur={false}
  />
}

export default TripGuests;